<ng-template #sideOverContent>
  <div class="e11-h-full e11-divide-y e11-divide-gray-200 e11-flex e11-flex-col e11-bg-white e11-shadow-xl">
    <div class="e11-flex-1 e11-h-0 e11-overflow-y-auto e11-overscroll-none">
      <div class="e11-py-6 e11-px-4 e11-bg-skin-primary sm:e11-px-6">
        <div class="e11-flex e11-items-center e11-justify-between e11-mb-2">
          <h3 class="e11-text-white e11-mb-0 e11-flex e11-items-center" id="slide-over-title">
            <div *ngIf="closeIconLocation === 'left'" class="e11-mr-3 e11-h-7 e11-flex e11-items-center">
              <button
                (click)="close()"
                type="button"
                class="e11-flex e11-items-center e11-justify-center e11-bg-skin-primary e11-h-6 e11-w-6 e11-rounded-md e11-text-white/70 hover:e11-text-white focus:e11-outline-none focus:e11-ring-1 focus:e11-ring-white hover:e11-ring-1 hover:e11-ring-white"
                [ngClass]="{ 'e11-cursor-wait': loading }"
              >
                <span class="e11-sr-only">Close panel</span>
                <span class="material-icons-outlined md-18"> arrow_back </span>
              </button>
            </div>
            {{ headerTitle | translate }}
          </h3>
          <div *ngIf="closeIconLocation === 'right'" class="e11-ml-3 e11-h-7 e11-flex e11-items-center">
            <button
              (click)="close()"
              type="button"
              class="e11-flex e11-items-center e11-justify-center e11-bg-skin-primary e11-h-6 e11-w-6 e11-rounded-md e11-text-white/70 hover:e11-text-white focus:e11-outline-none focus:e11-ring-1 focus:e11-ring-white hover:e11-ring-1 hover:e11-ring-white"
              [ngClass]="{ 'e11-cursor-wait': loading }"
            >
              <span class="e11-sr-only">Close panel</span>
              <span class="material-icons-outlined md-18"> close </span>
            </button>
          </div>
        </div>
        <div class="">
          <p class="e11-text-sm e11-text-white/70">
            {{ subTitle | translate }}
          </p>
        </div>
      </div>

      <ng-container *ngIf="isOpen">
        <ng-container *ngTemplateOutlet="uiTemplates['content']"></ng-container>
      </ng-container>
    </div>

    <div class="e11-flex e11-justify-end e11-p-4 e11-flex-shrink-0">
      <e11-button
        [value]="'Cancel' | translate"
        color="neutral"
        [icon]="'close'"
        [iconPosition]="'left'"
        [iconSize]="'md-18'"
        [style]="'pill outlined'"
        (click)="close()"
        [buttonGroup]="true"
      >
      </e11-button>
      <e11-button
        *ngIf="showDeleteButton"
        (click)="removeItem.emit()"
        value="{{ 'Delete' | translate }}"
        color="secondary"
        [buttonGroup]="true"
        [icon]="'delete'"
        [iconPosition]="'left'"
        [iconSize]="'md-18'"
        [style]="'pill outlined'"
      >
      </e11-button>

      <e11-button
        *ngIf="showSubmitButton"
        (click)="submitItem.emit()"
        value="{{ 'Save' | translate }}"
        color="primary-accent"
        [buttonGroup]="true"
        [disabled]="submitDisabled"
        [icon]="'save'"
        [iconPosition]="'left'"
        [iconSize]="'md-18'"
        [style]="'pill outlined'"
      >
      </e11-button>
    </div>
  </div>
</ng-template>
