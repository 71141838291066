// Framework
import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class ModalTermsOfUseComponent implements OnInit, OnChanges {
  @ViewChild('modalTerms') modalTerms: any
  @Input() status: boolean = false

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.openModal(changes.status.currentValue)
  }

  openModal(status: boolean) {
    this.modalTerms?.open()
  }
}
